<template>
  <Modal v-if="showModal" title="Add new person"
         @close="setShowModal(false)">
    <form @submit.prevent="createSalesPerson">
      <div class="mb-4">
        <label for="name" class="form-label">Name:</label>
        <input
            placeholder="name"
            v-model="name"
            id="name"
            name="name"
            type="text"
            :class="[{'is-invalid': !!errors.name}, 'form-control']"
        />
        <div class="invalid-feedback" v-if="errors.name?.length">{{ errors.name[0] }}</div>
      </div>
      <div class="mb-4">
        <label for="email" class="form-label">Email address:</label>
        <input
            placeholder="email"
            v-model="email"
            id="email"
            name="email"
            type="text"
            :class="[{'is-invalid': !!errors.email}, 'form-control']"
        />
        <div class="invalid-feedback" v-if="errors.email?.length">{{ errors.email[0] }}</div>
      </div>
      <button type="submit"
              class="btn btn-primary">Create
      </button>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { ref } from 'vue'
import axios from 'axios'

export default {
  name: 'SalesPersonCreate',
  components: { Modal },
  emits: ['submit'],
  props: {
    showModal: Boolean,
    setShowModal: Function
  },

  setup (props, { emit }) {
    const name = ref('')
    const email = ref('')
    const errors = ref({})
    const setShowModal = ref(props.setShowModal)

    const resetForm = () => {
      name.value = ''
      email.value = ''
    }

    const resetErrors = () => {
      errors.value = {}
    }

    const createSalesPerson = () => {
      resetErrors()

      const data = {
        name: name.value,
        email: email.value
      }

      axios.post(`${ process.env.VUE_APP_API_ENDPOINT }api/salespeople`, data, {
        headers: {
          'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
        },

      })
          .then((response) => {
            if (response.status >= 200) {
              setShowModal.value(false)
              resetForm()

              emit('submit', response.data.data)
            }
          })
          .catch(err => {
            errors.value = err.response.data.errors
          })
    }

    return { name, email, createSalesPerson, errors }
  }
}
</script>

<style scoped>

</style>