<template>
  <div class="col-12">
    <h1 class="text-center">{{ title }}</h1>
  </div>
  <div class="col-md-12 d-flex justify-content-end">
    <button type="button" class="btn btn-primary m-3 px-3 me-0" v-on:click.prevent="setShowModal(true)"><i
        class="bi bi-plus-circle me-1"></i>Add new person
    </button>
    <SalesPersonCreate
        :show-modal="showModal"
        :set-show-modal="setShowModal"
        @submit="onSubmit"
    />
  </div>
  <SalesPeopleTable
      v-if="laravelData.data?.length"
      :sales-people="laravelData.data"
      @delete="onDelete"
  />
  <div v-else>There is nothing on this page</div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useRoute, useRouter } from 'vue-router'
import SalesPeopleTable from '@/components/sales-people/components/SalesPeopleTable.vue'
import SalesPersonCreate from '@/components/sales-people/components/SalesPersonCreate.vue'

export default {
  name: 'SalesPeople',
  components: { SalesPersonCreate, SalesPeopleTable },

  props: {
    title: String
  },

  setup () {
    const router = useRouter()
    const route = useRoute()
    const laravelData = ref({})

    const showModal = ref(false)

    const setShowModal = (val) => {
      showModal.value = val
    }

    const getResults = (page = route.query?.page) => {
      router.push({
            name: 'SalesPeople',
            query: { page }
          }
      )

      axios.get(`${ process.env.VUE_APP_API_ENDPOINT }api/salespeople`, {
        headers: {
          'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
        }
      })
          .then((response) => {
            laravelData.value = response.data
          })
          .catch(response => {
            console.log('error' + response.toString())
          })
    }

    const onSubmit = (data) => {
      laravelData.value.data = [
        data,
        ...laravelData.value.data
      ]
    }

    const onDelete = (id) => {
      laravelData.value.data = laravelData.value.data.filter(item => item.id !== id)
    }

    getResults()

    return {
      laravelData,
      getResults,
      setShowModal,
      showModal,
      onSubmit,
      onDelete
    }
  }
}
</script>

<style scoped>

</style>