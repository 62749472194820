<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col" class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="salesPerson in salesPeople" v-bind:key="salesPerson.id">
        <th scope="row">{{ salesPerson.id }}</th>
        <td>{{ salesPerson.name }}</td>
        <td>{{ salesPerson.email }}</td>
        <td class="text-center">
          <button
              type="button"
              class="btn-outline-danger btn"
              v-on:click.prevent="deleteSalesPerson(salesPerson.id)"
          >Delete
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SalesPeopleTable',
  emits: ['delete'],

  props: {
    salesPeople: Array
  },

  setup (props, { emit }) {

    const deleteSalesPerson = (id) => {
      if (confirm('Are you sure you want to delete this sales person?')) {
        axios.delete(`${ process.env.VUE_APP_API_ENDPOINT }api/salespeople/${ id }`, {
          headers: {
            'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
          },
        })
            .then((response) => {
              if (response.status === 204) {
                emit('delete', id)
              }
            })
            .catch(err => {
              alert(err.message)
            })
      }
    }

    return {
      deleteSalesPerson
    }
  }

}
</script>

<style scoped>
.btn:hover {
  color: #fff;
}
</style>