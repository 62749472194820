<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-12">
        <SalesPeople title="Sales People" />
      </div>
    </div>
  </div>
</template>

<script>
import SalesPeople from '@/components/sales-people/SalesPeople.vue'

export default {
  name: 'SalesPeopleView',
  components: { SalesPeople }
}
</script>

<style scoped>

</style>